import { css, type Theme } from "@emotion/react";

import * as dimensions from "@styles/emotion-styles/dimensions";
import { helpers, animations } from "@styles/index";

export const boxTile = (theme: Theme) => css`
  ${animations.transition("box-shadow")};
  border-radius: ${dimensions.cardRadius};
  box-shadow: 0 1px 2px 0 ${helpers.hexToRgba(theme.text.body, 0.25)};
  &:hover {
    box-shadow: 0 1px 12px 0 ${helpers.hexToRgba(theme.text.body, 0.25)};
  }
  cursor: pointer;
  padding: 2.25rem;
  height: 18rem;
  width: 13rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  @media (min-width: ${dimensions.tablet}) {
    width: 22rem;
    height: 15rem;
  }
`;

export const boxTileDS4 = (theme: Theme) => css`
  box-shadow: none;
  border: 1px solid ${theme.system.borderBase};
  border-radius: ${dimensions.borderRadius};
`;

export const roleTile = css`
  padding: 0rem;
  height: 15rem;
  border: none;
  @media (min-width: ${dimensions.tablet}) {
    padding: 0.5rem;
  }
`;

export const contentBox = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-bottom: 1rem;
  text-align: center;
  margin-top: 7.5rem;
  width: 100%;

  @media (max-width: ${dimensions.tablet}) {
    margin-top: 0;
  }
`;

export const tilesContainter = css`
  margin: 2rem 0 !important;
  justify-content: center;
  @media (max-width: ${dimensions.tablet}) {
    & {
      margin: 1.5rem 0 !important;
    }
    div section label div + div {
      margin-right: 0;
    }
  }
`;

export const tileMobile = css`
  text-align: center;
  @media (max-width: ${dimensions.phone}) {
    padding: 1.5rem 1rem !important;
  }
`;

export const iconSpacing = css`
  margin-top: 1rem;
`;

export const textPadding = css`
  margin: 0.5rem 0;
`;
