import { Suspense, lazy } from "react";

import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { isProduction } from "@shared/utils/fullscriptEnv/fullscriptEnv";
const ExperimentTester = lazy(
  () => import(/* webpackChunkName: "development-experiment-tester" */ "../ExperimentTester")
);

const LazyExperimentTester = () => {
  const { isAdminImpersonating } = useSharedGlobalConfig();

  if (isProduction() && !isAdminImpersonating) return null;

  return (
    <Suspense fallback={null}>
      <ExperimentTester />
    </Suspense>
  );
};

export { LazyExperimentTester };
